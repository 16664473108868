<template>
    <div class="con-wrap">
        <CarrotTitle title="지원자 리스트">
            - CARROT 홈페이지를 통해 들어오는 지원자 리스트를 확인할 수 있습니다.
        </CarrotTitle>
        <div class="board">
            <div v-if="bbs.isAuth=='Y'" class="mt-40">
                <div>
                  <table class="table-row table-serach">
                    <tbody>
                    <tr>
                      <th width="180">지원일</th>
                      <td>
                        <CarrotDatePicker v-model="bbs.sdate" class="w-110px float-left"></CarrotDatePicker>
                        <span class="float-left ml-10 mr-10">~</span>
                        <CarrotDatePicker v-model="bbs.edate" class="w-110px float-left"></CarrotDatePicker>
                      </td>
                      <th width="180">연락처</th>
                      <td>
                        <input type="text" class="w-200px" v-model="bbs.phone">
                      </td>
                    </tr>
                    <tr>
                      <th width="180">이름</th>
                      <td>
                        <input type="text" class="w-200px" v-model="bbs.name">
                      </td>
                      <th width="180">E-mail</th>
                      <td>
                        <input type="text" class="w-200px" v-model="bbs.email">
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <button class="btn-default float-right mt-10 mb-40" @click="bbs.doSearch">검색</button>
                  <div class="clear"></div>
                    <div class="mb-20">

                      <!--
                      <select v-model="bbs.syear" @change="bbs.doSearch" class="w-100px float-left">
                        <option :value="v" v-for="(v, k) in bbs.search_years" :key="k">{{ v }}</option>
                      </select>
                      -->
                        <select v-model="bbs.sector_idx" @click="bbs.getDuty()" class="w-200px">
                            <option value="">직무 선택</option>
                            <option :value="v.deptcode" v-for="(v, k) in bbs.sector" :key="k" >{{ v.deptname }}</option>
                        </select>
                        <select v-model="bbs.duty_idx" @click="bbs.doSearch()" class="w-200px">
                          <option value="">직무 전체</option>
                          <option :value="v.deptcode" v-for="(v, k) in bbs.duty" :key="k" >{{ v.deptname }}</option>
                        </select>
                        <button class="btn-default float-right" @click="bbs.getExcel()">엑셀다운로드</button>
                        <div class="clear"></div>
                    </div>
                    
                    <table class="table-col">
                        <tbody>
                            <tr  class="hover-none" v-for="(irow, i) in bbs.list" :key="i">
                              <table class="table-col" >
                                <colgroup>
                                  <col width="40">
                                  <col width="150">
                                  <col width="150">
                                  <col width="80">
                                  <col width="180">
                                  <col width="120">
                                  <col width="80">
                                  <col width="100">
                                  <col width="80">
                                  <col width="80">
                                </colgroup>
                                <thead v-if="i==0">
                                <tr>
                                  <th>No</th>
                                  <th>지원부문</th>
                                  <th>직무</th>
                                  <th>이름</th>
                                  <th>E-mail</th>
                                  <th>연락처</th>
                                  <th>포트폴리오</th>
                                  <th>이력서</th>
                                  <th>지원일시</th>
                                  <th>삭제</th>
                                </tr>
                                </thead>
                                <tr>
                                  <td>{{ irow.num }}</td>
                                  <td>{{ irow.sector }}</td>
                                  <td>{{ irow.duty }}</td>
                                  <td>{{ irow.name }}</td>
                                  <td>{{ irow.email }}</td>
                                  <td>{{ irow.phone }}</td>
                                  <td><a :href="irow.portfolio" class="txt-blue">view</a></td>
                                  <td><a :href="irow.resume_file" class="txt-blue">{{ irow.resume_name }}</a></td>
                                  <td>{{ irow.createdate }}</td>
                                  <td><button class="btn-default float-left" @click="bbs.del(irow.idx)">삭제</button></td>
                                </tr>
                                <tr>
                                  <td colspan="10" class="contents">
                                    <div class=" txt-s1 cal-item txt-left preline"> <span v-html="irow.contents"></span>
                                    </div>
                                  </td>
                                </tr>
                              </table>

                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="10">내역이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import { useStore } from 'vuex';
import CarrotDatePicker from "@/components/common/CarrotDatePicker";


export default {
    layout:"myPIMS",

    components: {
      CarrotDatePicker
    },
    setup() {
        const router = new useRouter();
        const toast  = useToast();
        const store  = useStore();

        const pop = reactive({
            idx : 0,
            // Popup 노출 여부
            isShowView : false,
            isShowApplList : false,
            isShowApplView : false,

            showView : (idx) => {
                pop.idx = idx;
                pop.isShowView = true;
            },

            hideView : () => {
                pop.idx = 0;
                pop.isShowView = false;

                bbs.doSearch();
            },

            showApplicant : (idx) => {
                pop.idx = idx;
                pop.isShowApplList = true;
            },

            hideApplicant : () => {
                pop.idx = 0;
                pop.isShowApplList = false;

                bbs.doSearch();
            }
        });

        const bbs = reactive({
            isAuth : "N",

            page : 1,
            rows : 20,
            list : [],
            sector : [],
            duty  :[],
            sector_idx :0,
            duty_idx : 0,
            total : 0,
            sdate:"",
            edate:"",
            phone:"",
            email:"",
            name:"",

            state : '',

            getExcel : () => {
              let params = bbs.doSearch;
              let str = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
              location.href="/rest/mypims/RecruitExcelList?" + str;
            },
            doInit : () => {
                bbs.page = 1;
                bbs.doSearch();
                bbs.getSector();
            },

            doSearch : () => {
                let params = {
                    page   : bbs.page,
                    rows   : bbs.rows,
                    state  : bbs.state,
                    sdate  : bbs.sdate,
                    edate  : bbs.edate,
                    name  : bbs.name,
                    email  : bbs.email,
                    phone  : bbs.phone,
                    sector_idx : bbs.sector_idx,
                    duty_idx : bbs.duty_idx
                };
                axios.get('/rest/mypims/getRecruitList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                        bbs.isAuth = ( store.state.pd_auth == true ) ? "Y" : "N";
                        if( store.state.pd_auth == false ) {
                        //if( bbs.isAuth == "N" ){
                            Swal.fire({
                                title : 'Job Rotation 관리',
                                text  : '권한이 없습니다.'
                            }).then(() => {
                                router.go(-1);
                            });
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            getSector : () => {
              axios.get('/rest/external/getRecruitSector', ).then((res) => {
                if( res.data.err == 0 ){
                  bbs.sector  = res.data.list;
                  bbs.doSearch();
                } else {
                  if(res.data.err_msg) toast.error(res.data.err_msg);
                }
              });
            },
            getDuty: () => {
              let params = {
                idx   : bbs.sector_idx
              };
              bbs.duty_idx=0;

              axios.get('/rest/external/getRecruitDuty', { params : params }).then((res) => {
                if( res.data.err == 0 ){
                  bbs.duty  = res.data.list;
                  bbs.doSearch();
                } else {
                  if(res.data.err_msg) toast.error(res.data.err_msg);
                }
              });
            },
            del : (idx) => {
              let params = {
                idx  : idx
              };

              if( confirm("삭제 하시겠습니까? 삭제 시 복구 할 수 없습니다.") == true ){
                axios.get("/rest/mypims/delRecruit", { params : params }).then((res) => {
                  if( res.data.err == 0 ){
                    bbs.doSearch();
                  } else {
                    if(res.data.err_msg) toast.error(res.data.err_msg);
                  }
                });
              }
            },

        });

        onMounted(() => {
            // Mounted
            if( store.state.pd_auth == false) {
                router.go(-1);
                Swal.fire({
                    title : 'Job Rotation 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            bbs.doInit();
        });

        return {pop, bbs};
    }
}
</script>

<style lang="scss" scoped>
.contents {
  background-color: #EAEAEA;
}
</style>